import React from "react"
import { FormattedHTMLMessage, injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ intl }) => (
  <Layout>
    <SEO
      lang={intl.locale}
      title={`404: ${intl.formatMessage({ id: "title" })}`}
    />
    <div className="mx-auto text-center uppercase font-bold xl:container bg-opacity_white py-20 ">
      <h1 className="">
        <FormattedHTMLMessage id="notfound.header" />
      </h1>
      <p>
        <FormattedHTMLMessage id="notfound.description" />
      </p>
    </div>
  </Layout>
)

export default injectIntl(NotFoundPage)
